import {Injectable} from '@angular/core';
import {Observable} from "rxjs";
import {environment} from "@env/environment";
import {webSocket} from "rxjs/webSocket";
import {SubscriptionPayload} from "@shared/models/subscription-payload";
import {Stop} from "@shared/models/stop";


@Injectable({
  providedIn: 'root'
})
export class SubscriptionService {

  private wsSubject = webSocket<SubscriptionPayload>(environment.api.ws);

  constructor() {
  }

  getDisplayMessages(stop_id: string): Observable<SubscriptionPayload> {
    return this.wsSubject.multiplex(
      () => ({action: "subscribe", payload: {"subscriptions": <Stop>{StopID: stop_id}}}),
      () => ({action: 'unsubscribe'}),
      message => message.command == "display"
    )
  }


  getSchedule(stop_id: string) {
    return this.wsSubject.multiplex(
      () => ({action: "subscribe", payload: {"subscriptions": <Stop>{StopID: stop_id}}}),
      () => ({action: 'unsubscribe'}),
      message => message.command == "display"
    )
  }
}
