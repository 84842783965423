import {Injectable} from '@angular/core';
import {SubscriptionService} from "@shared/subscription.service";
import {filter, map, Observable} from "rxjs";
import {Message, MessageType} from "@shared/models/message";
import {Alert, Cause, Effect, Scope, Severity} from "@shared/models/alert";
import {CampaignMessage} from "@shared/models/campaign-message";

@Injectable({
  providedIn: 'root'
})
export class MessageService {

  //A Campaign with this title means it's a stop location
  // private STOP_LOCATIONS_CAMPAIGN_TITLE = 'BRT Stop Locations';
  private STOP_LOCATIONS_CAMPAIGN_TITLE = 'BRT LED Sign';

  constructor(private subscriptionService: SubscriptionService) {

  }

  /**
   * @param stop_id
   */
  getMessages(stop_id: string): Observable<Message[]> {
    return this.subscriptionService.getDisplayMessages(stop_id).pipe(
      map(p => {
        return [
          ...this.classifyServiceAlerts(p.payload.alerts),
          ...this.classifyCampaignMessages(p.payload.campaign_messages)
        ];
      }),
    )
  }

  /**
   * Process message payload and split into tuple - [location, generic] messages
   * @private
   * @param messages
   */
  private classifyCampaignMessages(messages: CampaignMessage[]): Message[] {
    return messages.flatMap((m: CampaignMessage) => {
      switch (true) {
        case m.Type === this.STOP_LOCATIONS_CAMPAIGN_TITLE:
          return <Message>{Type: MessageType.BRT_LOCATION, CampaignMessage: m};
        default:
          return <Message>{Type: MessageType.CAMPAIGN_GENERIC, CampaignMessage: m};
      }
    });

  }


  /**
   * @private
   * @param alerts
   */
  private classifyServiceAlerts(alerts: Alert[]): Message[] {

    return alerts.flatMap((a: Alert) => {
      switch (true) {
        case a.cause === Cause.MAINTENANCE && a.effect === Effect.MODIFIED_SERVICE:
          return MessageService.classifyBRTAlert(a);
        case a.severity === Severity.SEVERE:
          return <Message>{Type: MessageType.ALERT_SEVERE, Alert: a};
        case a.effect === Effect.NO_SERVICE && a.impacted_services.length > 0:
          return <Message>{Type: MessageType.ALERT_CANCELLATION, Alert: a};
        case a.effect === Effect.SIGNIFICANT_DELAYS && a.impacted_services.length > 0:
          return <Message>{Type: MessageType.ALERT_DELAY, Alert: a};
        case a.severity === Severity.WARNING:
          return <Message>{Type: MessageType.ALERT_WARNING, Alert: a};
        default:
          return <Message>{Type: MessageType.ALERT_INFO, Alert: a};
      }
    });
  }


  private static classifyBRTAlert(alert: Alert) {
    switch (true) {
      case MessageService.isAlertActive(alert) && alert.scope === Scope.THIS_STOP:
        return <Message>{
          Type: MessageType.BRT_CURRENT_THIS_STATION,
          Alert: alert,
        }
      case MessageService.isAlertActive(alert) && alert.scope === Scope.OTHER_STOP:
        return <Message>{
          Type: MessageType.BRT_CURRENT_OTHER_STATIONS,
          Alert: alert
        }
      case MessageService.isAlertUpcoming(alert) && alert.scope === Scope.THIS_LINE:
        return <Message>{
          Type: MessageType.BRT_FUTURE_LINE,
          Alert: alert
        }
      default:
        //Invalid
        return [];
    }

  }


  private static isAlertActive(alert: Alert) {
    return !this.isAlertUpcoming(alert) && !this.isAlertPast(alert);
  }

  private static isAlertUpcoming(alert: Alert) {
    const now = Date.now() / 1000;

    return alert.active_period.filter(p => {
      return p.start && (p.start > now);
    }).length > 0;
  }

  private static isAlertPast(alert: Alert) {
    const now = Date.now() / 1000;

    return alert.active_period.filter(p => {
      return p.start && (p.end > now);
    }).length === 0;
  }

  public static isMessageBRT(m: Message) {
    return [
      MessageType.BRT_CURRENT_THIS_STATION,
      MessageType.BRT_CURRENT_OTHER_STATIONS,
      MessageType.BRT_FUTURE_LINE
    ].includes(m.Type)
  }


}
