export enum Cause {
  UNKNOWN_CAUSE = 'UNKNOWN_CAUSE',
  OTHER_CAUSE = 'OTHER_CAUSE',
  TECHNICAL_PROBLEM = 'TECHNICAL_PROBLEM',
  STRIKE = 'STRIKE',
  DEMONSTRATION = 'DEMONSTRATION',
  ACCIDENT = 'ACCIDENT',
  HOLIDAY = 'HOLIDAY',
  WEATHER = 'WEATHER',
  MAINTENANCE = 'MAINTENANCE',
  CONSTRUCTION = 'CONSTRUCTION',
  POLICE_ACTIVITY = 'POLICE_ACTIVITY',
  MEDICAL_EMERGENCY = 'MEDICAL_EMERGENCY'
}

export enum Effect {
  NO_SERVICE = 'NO_SERVICE',
  REDUCED_SERVICE = 'REDUCED_SERVICE',
  SIGNIFICANT_DELAYS = 'SIGNIFICANT_DELAYS',
  DETOUR = 'DETOUR',
  ADDITIONAL_SERVICE = 'ADDITIONAL_SERVICE',
  MODIFIED_SERVICE = 'MODIFIED_SERVICE',
  OTHER_EFFECT = 'OTHER_EFFECT',
  UNKNOWN_EFFECT = 'UNKNOWN_EFFECT',
  STOP_MOVED = 'STOP_MOVED'
}

export enum Severity {
  UNKNOWN_SEVERITY = 'UNKNOWN_SEVERITY',
  INFO = 'INFO',
  WARNING = 'WARNING',
  SEVERE = 'SEVERE'
}

export enum Scope {
  THIS_STOP = 'THIS_STOP',
  OTHER_STOP = 'OTHER_STOP',
  THIS_LINE = 'THIS_LINE',
}

export interface ImpactedService {
  departs: string,
  destination: string
}

export interface Alert {
  route: string;
  content: string;
  scope: Scope;
  severity: Severity;
  cause: Cause;
  effect: Effect;
  impacted_services: ImpactedService[]
  type: string;
  upcoming: boolean;
  active_period: {
    start: number;
    end: number;
  }[]
}
