import {Pipe, PipeTransform} from '@angular/core';
import {Cause} from "@shared/models/alert";

@Pipe({
  name: 'formatAlertCause'
})
export class FormatAlertCausePipe implements PipeTransform {

  transform(cause: Cause): unknown {
    switch (cause) {
      case Cause.UNKNOWN_CAUSE:
        return 'an unknown cause';
      case Cause.OTHER_CAUSE:
        return 'an other cause';
      case Cause.TECHNICAL_PROBLEM:
        return 'a technical problem';
      case Cause.STRIKE:
        return 'a strike';
      case Cause.DEMONSTRATION:
        return 'a demonstration';
      case Cause.ACCIDENT:
        return 'an accident';
      case Cause.HOLIDAY:
        return 'holidays';
      case Cause.WEATHER:
        return 'weather';
      case Cause.MAINTENANCE:
        return 'maintenance';
      case Cause.CONSTRUCTION:
        return 'construction';
      case Cause.POLICE_ACTIVITY:
        return 'police activity';
      case Cause.MEDICAL_EMERGENCY:
        return 'a medical emergency';
    }

    return '';
  }

}
