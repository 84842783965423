import {NgModule} from '@angular/core';
import {CommonModule, DatePipe} from '@angular/common';

import {ApiService} from "@shared/http/api.service";
import {IdentityService} from "@shared/identity.service";
import {HttpClientModule} from "@angular/common/http";
import {HttpInterceptors} from "@shared/http/interceptors";
import {SubscriptionService} from "@shared/subscription.service";
import {MessageService} from "@shared/message.service";
import {FormatServicePipe} from "@shared/pipes/format-service.pipe";
import {FormatRoutePipe} from './pipes/format-route.pipe';
import { FormatAlertCausePipe } from './pipes/format-alert-cause.pipe';


@NgModule({
  declarations: [

    FormatRoutePipe,
     FormatAlertCausePipe
  ],
  imports: [
    CommonModule,
    HttpClientModule
  ],
  exports: [
    FormatRoutePipe,
    FormatAlertCausePipe
  ],
  providers: [
    HttpInterceptors,
    ApiService,
    IdentityService,
    SubscriptionService,
    MessageService,
    FormatServicePipe,
    DatePipe
  ]
})
export class SharedModule {
}
