import {Pipe, PipeTransform} from '@angular/core';
import {ImpactedService} from "@shared/models/alert";
import {DatePipe} from "@angular/common";

@Pipe({
  name: 'formatService'
})
export class FormatServicePipe implements PipeTransform {
  constructor(private datePipe: DatePipe) {
  }

  transform(service: ImpactedService, ...args: unknown[]): unknown {
    const time = this.datePipe.transform(service.departs, 'h:mma')?.toLowerCase()
    return `${time} to ${service.destination}`;

  }

}
