<div>
  <h1 [class]="textClass"><ng-content></ng-content>
    <svg [class]="exclamationClass" xmlns="http://www.w3.org/2000/svg" x="0px" y="0px" viewBox="0 0 1000 1000">
      <g>
        <circle r="500" cx="500" cy="500"></circle>
        <path
          d="M500,880.2c-53.1,0-96.2-43.1-96.2-96.2s43.1-96.2,96.2-96.2c53.1,0,96.2,43.1,96.2,96.2S553.1,880.2,500,880.2z M601.7,233.3l-37,335.5c-3.8,34.3-32.8,60.3-67.3,60.3l0,0c-35,0-64.2-26.6-67.4-61.4l-31.7-336c-5.7-60,41.5-111.9,101.8-111.9l0,0C560.9,119.8,608.3,172.7,601.7,233.3z"/>
      </g>
    </svg>
  </h1>
</div>
