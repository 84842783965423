import {Injectable} from '@angular/core';
import {Router} from "@angular/router";
import {IdentityService} from "@shared/identity.service";

@Injectable({
  providedIn: 'root'
})
export class AutoRouterService {

  constructor(private router: Router, private identityService: IdentityService) {
  }

  public bindIdentityToRouter() {
    this.identityService.identity$.subscribe(identity => {
      const subscription = identity.Subscriptions;

      if (subscription !== undefined) {
        this.router.navigate(['messages', subscription.StopID]).then(r => null);
      }
    })
  }
}
