import {Injectable} from '@angular/core';
import {identity, interval, Observable, of, startWith, Subject, switchMap} from "rxjs";
import {Identity} from "./models/identity";
import {ApiService} from "@shared/http/api.service";

@Injectable({
  providedIn: 'root'
})
export class IdentityService {

  private IDENTITY_REFRESH_INTERVAL_MS: number = 1000 * 60 * 60 * 12; //12 hour?

  /**
   * Auto refresh observable to hook the API request
   * @private
   */
  private readonly autoRefresh$ = interval(this.IDENTITY_REFRESH_INTERVAL_MS).pipe(
    startWith(0)
  );

  public identity$:Observable<Identity>

  /**
   * @param apiService
   */
  constructor(private apiService: ApiService) {

    //Set up automatic refresh hook. Will only activate once this.identity$ is subscribed to
    this.identity$ = this.autoRefresh$.pipe(
      //Swap the counter for a request for the identity
      switchMap(() => this.apiService.getIdentity()),
    );
  }

}
