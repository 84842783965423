import {Injectable} from '@angular/core';
import {HttpClient} from "@angular/common/http";
import {Identity} from '../models/identity';
import {map, Observable} from "rxjs";

@Injectable({
  providedIn: 'root'
})
export class ApiService {

  constructor(private http: HttpClient) {

  }

  public getIdentity(): Observable<Identity> {
    return this.http.get<Identity>('identity');
  }
}
