import {Injectable} from '@angular/core';
import {
  HttpRequest,
  HttpHandler,
  HttpEvent,
  HttpInterceptor
} from '@angular/common/http';
import {Observable} from 'rxjs';
import {retryBackoff} from "backoff-rxjs";

@Injectable()
export class RetryInterceptor implements HttpInterceptor {

  /**
   * Backoff before beginning exponential retry
   * @private
   */
  private INITIAL_BACKOFF_INTERVAL_MS = () => Math.random() * 5000;
  private MAX_BACKOFF_INTERVAL_MS = 1000 * 60; //60 seconds

  constructor() {
  }

  /**
   * Overload default request to append a backoff
   * @private
   * @param request
   * @param next
   */
  intercept(request: HttpRequest<unknown>, next: HttpHandler): Observable<HttpEvent<unknown>> {
    return next.handle(request).pipe(
      retryBackoff({
        initialInterval: this.INITIAL_BACKOFF_INTERVAL_MS(),
        maxInterval: this.MAX_BACKOFF_INTERVAL_MS,
        resetOnSuccess: false
      }));
  }
}
