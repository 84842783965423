import {Component, OnInit} from '@angular/core';
import {BaseMessageType} from "@app/message-display/base-message-type";

@Component({
  selector: 'app-brt-future-line',
  templateUrl: './brt-future-line.component.html',
  styleUrls: ['./brt-future-line.component.scss']
})
export class BrtFutureLineComponent extends BaseMessageType implements OnInit {

  constructor() {
    super();
  }

  ngOnInit(): void {
  }

}
