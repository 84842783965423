<ng-container *ngIf="(message$ | async) as message; else clock" [ngSwitch]="message.Type">
  <ng-container *appRerender="message">
    <app-alert-cancellation *ngSwitchCase="MessageType.ALERT_CANCELLATION" [message]="message"></app-alert-cancellation>
    <app-alert-delay *ngSwitchCase="MessageType.ALERT_DELAY" [message]="message"></app-alert-delay>
    <app-alert-severe *ngSwitchCase="MessageType.ALERT_SEVERE" [message]="message"></app-alert-severe>
    <app-alert-warning *ngSwitchCase="MessageType.ALERT_WARNING" [message]="message"></app-alert-warning>
    <app-alert-info *ngSwitchCase="MessageType.ALERT_INFO" [message]="message"></app-alert-info>
    <app-brt-current-other-stations *ngSwitchCase="MessageType.BRT_CURRENT_OTHER_STATIONS" [message]="message"></app-brt-current-other-stations>
    <app-brt-current-this-station *ngSwitchCase="MessageType.BRT_CURRENT_THIS_STATION" [message]="message"></app-brt-current-this-station>
    <app-brt-future-line *ngSwitchCase="MessageType.BRT_FUTURE_LINE" [message]="message"></app-brt-future-line>
    <app-brt-location *ngSwitchCase="MessageType.BRT_LOCATION" [message]="message"></app-brt-location>
    <app-campaign-generic *ngSwitchCase="MessageType.CAMPAIGN_GENERIC" [message]="message"></app-campaign-generic>
  </ng-container>
</ng-container>
<ng-template #clock>
  <app-clock></app-clock>
</ng-template>
