import { Component, OnInit } from '@angular/core';
import {BaseMessageType} from "@app/message-display/base-message-type";

@Component({
  selector: 'app-alert-delay',
  templateUrl: './alert-delay.component.html',
  styleUrls: ['./alert-delay.component.scss']
})
export class AlertDelayComponent extends BaseMessageType implements OnInit {

  constructor() {
    super();
  }

  ngOnInit(): void {
  }

}
