import {Component, Input, OnInit} from '@angular/core';
import {BaseMessageType} from "@app/message-display/base-message-type";

@Component({
  selector: 'app-brt-location',
  templateUrl: './brt-location.component.html',
  styleUrls: ['./brt-location.component.scss']
})
export class BrtLocationComponent extends BaseMessageType implements OnInit {

  constructor() {
    super();
  }

  ngOnInit(): void {
  }

}
