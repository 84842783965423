import {Component, OnInit} from '@angular/core';
import {AutoRouterService} from "@shared/auto-router.service";
import {ActivatedRoute} from "@angular/router";
import {filter} from "rxjs";

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent implements OnInit {
  title = 'brt-alerts';

  constructor(private autoRouterService:AutoRouterService, private route:ActivatedRoute) {
  }

  ngOnInit() {
    this.route.queryParamMap.pipe(
      filter(params => params.has('bind'))
    ).subscribe(() =>{
      console.log('binding');
      this.autoRouterService.bindIdentityToRouter();
    })
  }
}
