<app-heading exclamationClass="white-pink" textClass="white-pink">Go to bus stop</app-heading>
<app-message-pane *appRerender="message">
  {{message.Alert.route|formatRoute}}:<br/>
  <ng-container *ngIf="message.Alert.impacted_services.length > 0; else noServices">
    Buses replace the <br>
    <div *ngFor="let service of message.Alert.impacted_services | slice:0:3;">
      {{service|formatService}}
    </div>
  </ng-container>
  <ng-template #noServices>{{message.Alert.content}}</ng-template>
</app-message-pane>
