import {Component, OnInit} from '@angular/core';
import {BaseMessageType} from "@app/message-display/base-message-type";

@Component({
  selector: 'app-brt-current-this-station',
  templateUrl: './brt-current-this-station.component.html',
  styleUrls: ['./brt-current-this-station.component.scss']
})
export class BrtCurrentThisStationComponent extends BaseMessageType implements OnInit {

  constructor() {
    super();
  }

  ngOnInit(): void {
  }

}
