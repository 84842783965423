import {Injectable} from '@angular/core';
import {
  HttpRequest,
  HttpHandler,
  HttpEvent,
  HttpInterceptor
} from '@angular/common/http';
import {Observable} from 'rxjs';
import {IdentityService} from "@shared/identity.service";

@Injectable()
export class IdentityInterceptor implements HttpInterceptor {

  constructor() {
  }

  /**
   * If we need to append the identity to API requests - not used at the moment
   * @param request
   * @param next
   */
  intercept(request: HttpRequest<unknown>, next: HttpHandler): Observable<HttpEvent<unknown>> {
    //noop
    return next.handle(request);
  }
}
