import {
  AfterViewInit,
  Component,
  ElementRef,
  OnChanges,
  OnInit,
  Renderer2,
  SimpleChanges,
  ViewChild
} from '@angular/core';
import {concatMap, ignoreElements, startWith, Subject, timer} from "rxjs";

@Component({
  selector: 'app-message-pane',
  templateUrl: './message-pane.component.html',
  styleUrls: ['./message-pane.component.scss']
})
export class MessagePaneComponent implements OnInit, AfterViewInit {
  @ViewChild('outlet') outlet!: ElementRef;
  @ViewChild('content') messageContent!: ElementRef;

  private PAGE_SPAN_STRING = '>>';
  private PAGE_TIME_MS = 5000;

  private availableHeight$ = new Subject<number>();
  private message$ = new Subject<string>();
  private page$ = new Subject<string>();

  constructor(private renderer: Renderer2) {
  }

  ngOnInit(): void {

    //Hook the height adjustment early
    this.availableHeight$.subscribe(height => {
      this.renderer.setStyle(this.messageContent.nativeElement, 'height', `${height}px`);
      this.renderer.setStyle(this.outlet.nativeElement, 'height', `${height}px`);
    });

    this.message$.subscribe(m => this.pageMessage(m));

    this.page$.pipe(
      concatMap(v => timer(this.PAGE_TIME_MS).pipe(
        ignoreElements(),
        startWith(v)
      ))
    ).subscribe({
      next: text => this.setOutlet(text),
      complete: () => this.messagePagingComplete()
    });

  }

  ngAfterViewInit(): void {

    this.availableHeight$.next(window.screen.height - this.messageContent.nativeElement.offsetTop);
    this.message$.next(this.messageContent.nativeElement.innerHTML.trim());
  }

  private setOutlet(text: string) {
    this.outlet.nativeElement.innerHTML = text;
  }

  private pageMessage(message: string) {

    const messageEl = this.messageContent.nativeElement;
    const words = message.split(' ');
    let page = '';

    words.forEach((word, i, words) => {

      //If it's the first word, don't bother doing any testing. Also removes leading space.
      if (i === 0) {
        page = word;
        return;
      }

      const last = i === words.length - 1
      let test = `${page} ${word}`;

      //If last word, won't need the page span string
      if (!last) {
        test += ` ${this.PAGE_SPAN_STRING}`;
      }

      messageEl.innerHTML = test;

      //Overflow!
      if (messageEl.scrollHeight > messageEl.clientHeight) {
        //Emit this page with overflow
        this.page$.next(`${page} ${this.PAGE_SPAN_STRING}`);
        //Reset next page with leading overflow
        page = `${this.PAGE_SPAN_STRING} ${word}`;
      } else {
        page += ` ${word}`;
      }

      if (last) {
        //Output this page, then close the page
        this.page$.next(page);
        this.page$.complete();
      }

    });
  }

  /**
   * Let parent components know that we've reached the last page
   * Opted to do this as it's purely for display logic, so not via a service/chained @Outputs etc
   */
  messagePagingComplete(): void {
    this.outlet.nativeElement.dispatchEvent(new CustomEvent('MessagePagingComplete', {
      bubbles: true,
    }));
  }
}
