import {ChangeDetectorRef, Component, DoCheck, HostListener, OnInit} from '@angular/core';
import {ActivatedRoute} from "@angular/router";
import {MessageService} from "@shared/message.service";
import {Message, MessageType} from "@shared/models/message";
import {
  BehaviorSubject, map, of, startWith, Subject, switchMap
} from "rxjs";

@Component({
  selector: 'app-message-display',
  templateUrl: './message-display.component.html',
  styleUrls: ['./message-display.component.scss']
})
export class MessageDisplayComponent implements OnInit {
  private running: Boolean = false;

  @HostListener('MessagePagingComplete', ['$event'])
  onCustomEventCaptured(event: CustomEvent) {
    //Capture the completion of the paging of the child component and get next message
    this.messageComplete$.next();
  }

  readonly MessageType = MessageType;

  /**
   * Message currently being displayed
   */
  private messageSubject = new BehaviorSubject<Message | null>(null);
  public message$ = this.messageSubject.asObservable();

  private messageComplete$ = new Subject<void>();

  private NUM_ALERTS_HIDE_CAMPAIGNS = 5;

  constructor(private route: ActivatedRoute, private messageService: MessageService, private cdr: ChangeDetectorRef) {
  }

  ngOnInit(): void {
    const stop_id = <string>this.route.snapshot.paramMap.get('stop_id');


    this.messageService.getMessages(stop_id).pipe(
      map(messages => {
        //Use filters here as they're small arrays. Could use reduce if large number of alerts here
        const num_severe = messages.filter(m => m.Type === MessageType.ALERT_SEVERE).length;
        const num_brt = messages.filter(m => MessageService.isMessageBRT(m)).length;
        const num_brt_current_this_station = messages.filter(m => m.Type === MessageType.BRT_CURRENT_THIS_STATION).length;
        const num_all_alerts = messages.filter(m => m.Type !== MessageType.CAMPAIGN_GENERIC).length;

        //Filter out generic campaigns if these other conditions
        if (num_severe || num_brt || num_all_alerts > this.NUM_ALERTS_HIDE_CAMPAIGNS) {
          messages = messages.filter(m => m.Type != MessageType.CAMPAIGN_GENERIC)
        }

        //Filter out BRT location campaigns if no BRT active at this station
        if (num_brt_current_this_station === 0) {
          messages = messages.filter(m => m.Type != MessageType.BRT_LOCATION)
        }

        //Sort (make BRT location show after BRT messages) numbers otherwise irrelevant as will be in a loop
        //MessageType Enum order defines order... maybe this is bad
        messages = messages.sort((a, b) => a.Type - b.Type);

        return messages
      }),
      switchMap(messages => {
        let messageIndex = 0;
        if(messages.length === 0){
          return of(null);
        }

        return this.messageComplete$.pipe(
          startWith(null),
          map(() => messages[messageIndex++ % messages.length])
        )
      })
    ).subscribe(this.messageSubject);
  }


}
