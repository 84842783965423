import {HTTP_INTERCEPTORS} from '@angular/common/http';
import {ApiPrefixInterceptor} from "@shared/http/api-prefix.interceptor";
import {IdentityInterceptor} from "@shared/http/identity.interceptor";
import {RetryInterceptor} from "@shared/http/retry.interceptor";

export const HttpInterceptors = [
  {provide: HTTP_INTERCEPTORS, useClass: ApiPrefixInterceptor, multi: true},
  {provide: HTTP_INTERCEPTORS, useClass: IdentityInterceptor, multi: true},
  {provide: HTTP_INTERCEPTORS, useClass: RetryInterceptor, multi: true},
]
