import {Alert, ImpactedService} from "@shared/models/alert";
import {CampaignMessage} from "@shared/models/campaign-message";

/**
 * The order of this enum is the precedence for display.
 */
export enum MessageType {
  BRT_CURRENT_THIS_STATION,
  BRT_LOCATION,
  BRT_CURRENT_OTHER_STATIONS,
  BRT_FUTURE_LINE,
  ALERT_SEVERE,
  ALERT_CANCELLATION,
  ALERT_DELAY,
  ALERT_WARNING,
  ALERT_INFO,
  CAMPAIGN_GENERIC,
}

export interface Message {
  Alert: Alert;
  CampaignMessage: CampaignMessage;
  Type: MessageType;
}
