import {Component, OnInit} from '@angular/core';
import {BaseMessageType} from "@app/message-display/base-message-type";

@Component({
  selector: 'app-campaign-generic',
  templateUrl: './campaign-generic.component.html',
  styleUrls: ['./campaign-generic.component.scss']
})
export class CampaignGenericComponent extends BaseMessageType implements OnInit {

  constructor() {
    super();
  }

  ngOnInit(): void {
  }

}
