import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'formatRoute'
})
export class FormatRoutePipe implements PipeTransform {

  transform(value: string, ...args: unknown[]): unknown {
    return value.replace(new RegExp(' +\\([^)]+\\)', 'i'), '');
  }

}
