import {Component, OnInit} from '@angular/core';
import {BaseMessageType} from "@app/message-display/base-message-type";

@Component({
  selector: 'app-alert-severe',
  templateUrl: './alert-severe.component.html',
  styleUrls: ['./alert-severe.component.scss']
})
export class AlertSevereComponent extends BaseMessageType implements OnInit {

  constructor() {
    super();
  }

  ngOnInit(): void {
  }

}
