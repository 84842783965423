import { Directive,
  Input,
  TemplateRef,
  ViewContainerRef } from '@angular/core';
import {Message} from "@shared/models/message";

@Directive({
  selector: '[appRerender]'
})
export class RerenderDirective {
  constructor(
    private templateRef:    TemplateRef<any>,
    private viewContainer:  ViewContainerRef
  ) {}

  // if detects changes of the input `val`, clear and rerender the view
  @Input() set appRerender(m:Message) {
    this.viewContainer.clear();
    this.viewContainer.createEmbeddedView(this.templateRef);
  }
}
