import {NgModule} from '@angular/core';
import {BrowserModule} from '@angular/platform-browser';

import {AppComponent} from './app.component';
import {CommonModule} from "@angular/common";
import {SharedModule} from "@shared/shared.module";
import {AppRoutingModule} from './app-routing.module';
import {MessageDisplayComponent} from './message-display/message-display.component';
import {StatusDisplayComponent} from './status-display/status-display.component';
import {CampaignGenericComponent} from './message-display/campaign-generic/campaign-generic.component';
import {BrtLocationComponent} from './message-display/brt-location/brt-location.component';
import {
  BrtCurrentThisStationComponent
} from './message-display/brt-current-this-station/brt-current-this-station.component';
import {
  BrtCurrentOtherStationsComponent
} from './message-display/brt-current-other-stations/brt-current-other-stations.component';
import {BrtFutureLineComponent} from './message-display/brt-future-line/brt-future-line.component';
import {AlertSevereComponent} from './message-display/alert-severe/alert-severe.component';
import {AlertCancellationComponent} from './message-display/alert-cancellation/alert-cancellation.component';
import {AlertDelayComponent} from './message-display/alert-delay/alert-delay.component';
import {AlertWarningComponent} from './message-display/alert-warning/alert-warning.component';
import {HeadingComponent} from './message-display/heading/heading.component';
import {MessagePaneComponent} from './message-display/message-pane/message-pane.component';
import {FormatServicePipe} from "@shared/pipes/format-service.pipe";
import {ClockComponent} from "@app/message-display/clock/clock.component";
import { RerenderDirective } from '@shared/rerender.directive';
import { AlertInfoComponent } from './message-display/alert-info/alert-info.component';

@NgModule({
  declarations: [
    AppComponent,
    MessageDisplayComponent,
    StatusDisplayComponent,
    CampaignGenericComponent,
    BrtLocationComponent,
    BrtCurrentThisStationComponent,
    BrtCurrentOtherStationsComponent,
    BrtFutureLineComponent,
    AlertSevereComponent,
    AlertCancellationComponent,
    AlertDelayComponent,
    AlertWarningComponent,
    HeadingComponent,
    MessagePaneComponent,
    FormatServicePipe,
    ClockComponent,
    RerenderDirective,
    AlertInfoComponent
  ],
  imports: [
    BrowserModule,
    CommonModule,
    SharedModule,
    AppRoutingModule,
  ],
  providers: [],
  bootstrap: [AppComponent]
})
export class AppModule {
}
